import { httpApi as httpNodeApi, setAuthorizationToken } from '@nucleus-care/nucleuscare-backend-client';
import Confirmation from 'components/Confirmation/Confirmation';
import DeviceContactsForm from 'components/DeviceContactsForm/DeviceContactsForm';
import DeviceUserForm from 'components/DeviceUserForm/DeviceUserForm';
import Header from 'components/Header/Header';
import OptionalQuestionsForm from 'components/OptionalQuestionsForm/OptionalQuestionsForm';
import OrdererForm from 'components/OrdererForm/OrdererForm';
import WifiForm from 'components/WifiForm/WifiForm';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { authApi, setCAuthorizationToken } from 'utils/api/httpClients';

type ConsumerOrderFamilyMember = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  admin: boolean;
};

export interface IShipTo {
  state: string;
  phone: string;
  residential: boolean;
  street2: string;
  company: string | null;
  addressVerified: string;
  city: string;
  postalCode: string;
  street1: string;
  country: string;
  name: string;
  street3: string | null;
}

export type ConsumerOrderResponse = {
  metadata: {
    shipTo: IShipTo;
    additionalComments: string;
    deviceOrdererAge: string;
    deviceUserAge: string;
    deviceUserGender: string;
    deviceUserMaritalStatus: string;
    deviceUserNumberOfResidentsInHousehold: string;
    wifiPassword: string;
    wifiSsid: string;
  };
  deviceOrdererAddress: string;
  deviceOrdererFirstName: string;
  deviceOrdererEmail: string;
  deviceOrdererLastName: string;
  deviceOrdererPhoneNumber: string;
  shippingAddress: string;
  shippedDate: string;
  deviceUserFirstName: string;
  deviceUserLastName: string;
  deviceUserPhoneNumber: string;
  enrollmentDate: Date;
  deviceQuantity: number;
  familyMembers: ConsumerOrderFamilyMember[];
  formExpired: boolean;
  preferredShippingAddress: string;
};

type ConsumerOrderOrderer = {
  deviceOrdererAddress: string;
  deviceOrdererFirstName: string;
  deviceOrdererLastName: string;
  deviceOrdererEmail: string;
  deviceOrdererPhoneNumber: string;
  shippingAddress: string;
};

type ConsumerOrderDeviceUser = {
  deviceUserFirstName: string;
  deviceUserLastName: string;
  deviceUserPhoneNumber: string;
  deviceUserDeviceNames?: string[];
};

type ConsumerOrderWifi = {
  wifiSsid: string;
  wifiPassword: string;
};

type ConsumerOrderOptional = {
  additionalComments: string;
  deviceOrdererAge: string;
  deviceUserAge: string;
  deviceUserGender: string;
  deviceUserMaritalStatus: string;
  deviceUserNumberOfResidentsInHousehold: string;
};

export type ConsumerOrderData = {
  modifiedAddress?: {
    deviceUser: IShipTo | undefined;
    deviceOrderer: IShipTo | undefined;
  };
  orderer: ConsumerOrderOrderer;
  deviceUser: ConsumerOrderDeviceUser;
  deviceContacts: ConsumerOrderFamilyMember[];
  wifi: ConsumerOrderWifi;
  optional: ConsumerOrderOptional;
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContainerWrapper = styled.div`
  flex: 1;
  display: flex;
  overflow-y: auto;
  max-width: 1440px;
  @media (min-width: 1441px) {
    display: flex;
    justify-content: center;
    align-self: center;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  overflow-y: auto;
  max-width: 1440px;
  @media (min-width: 1441px) {
    justify-content: flex-start;
  }
`;

const Purchase = () => {
  const navigate = useNavigate();
  const { consumerOrderId } = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState<ConsumerOrderData>({
    modifiedAddress: {
      deviceOrderer: undefined,
      deviceUser: undefined,
    },
    orderer: {} as unknown as ConsumerOrderOrderer,
    deviceUser: {} as unknown as ConsumerOrderDeviceUser,
    deviceContacts: [],
    wifi: {} as unknown as ConsumerOrderWifi,
    optional: {} as unknown as ConsumerOrderOptional,
  });
  const [shippedStatus, setShippedStatus] = useState<string | null>(null);
  const [patientName, setPatientName] = useState('');
  const [updatedInput, setUpdatedInput] = useState<boolean>(false);
  const [usingExistingPatient, setUsingExistingPatient] = useState<boolean>(false);
  const [existingPatientId, setExistingPatientId] = useState<string | null>(null);
  const [deviceQuantity, setDeviceQuantity] = useState<number>(0);
  const [preferredShippingAddress, setPreferredShippingAddress] = useState<string>('');
  const [patientHasExistingContacts, setPatientHasExistingContacts] = useState<boolean>(false);
  const steps = [
    { component: OrdererForm, key: 'orderer' },
    { component: DeviceUserForm, key: 'deviceUser' },
    { component: DeviceContactsForm, key: 'deviceContacts' },
    { component: WifiForm, key: 'wifi' },
    { component: OptionalQuestionsForm, key: 'optional' },
    { component: Confirmation, key: 'confirmation' },
  ];

  const nextStep = () => setCurrentStep(prev => prev + 1);
  const prevStep = () => setCurrentStep(prev => prev - 1);

  const handleFormDataChange = (key, data) => {
    setFormData(prev => ({
      ...prev,
      [key]: data,
    }));
    setUpdatedInput(true);
  };

  const isValidLocalStorageData = data => {
    return (
      data &&
      Object.keys(data).length > 0 &&
      data.orderer &&
      Object.keys(data.orderer).length > 0 &&
      data.deviceContacts &&
      data.deviceContacts.length > 0 &&
      data.wifi &&
      Object.keys(data.wifi).length > 0 &&
      data.optional &&
      Object.keys(data.optional).length > 0
    );
  };

  const getOrderData = async () => {
    await authApi
      .post('/consumer-orders/authenticate', { orderId: consumerOrderId })
      .then(response => {
        const { data } = response;
        const { token } = data;
        setAuthorizationToken(token);
        setCAuthorizationToken(token);
      })
      .catch(error => {
        console.log('error: ', error);
      });

    httpNodeApi
      .get<ConsumerOrderResponse>(`consumer-orders/${consumerOrderId}`)
      .then(response => {
        const { data } = response;
        const { metadata } = data;
        const {
          shipTo,
          additionalComments,
          deviceOrdererAge,
          deviceUserAge,
          deviceUserGender,
          deviceUserMaritalStatus,
          deviceUserNumberOfResidentsInHousehold,
          wifiPassword,
          wifiSsid,
        } = metadata;
        const {
          deviceOrdererAddress,
          deviceOrdererFirstName,
          deviceOrdererEmail,
          deviceOrdererLastName,
          deviceOrdererPhoneNumber,
          familyMembers,
          shippingAddress,
          shippedDate,
          deviceUserFirstName,
          deviceUserLastName,
          deviceUserPhoneNumber,
          enrollmentDate,
          formExpired,
          deviceQuantity,
          preferredShippingAddress,
        } = data;

        const apiData = {
          shipTo: shipTo,
          orderer: { deviceOrdererAddress, deviceOrdererFirstName, deviceOrdererLastName, deviceOrdererEmail, deviceOrdererPhoneNumber, shippingAddress },
          deviceUser: { deviceUserFirstName, deviceUserLastName, deviceUserPhoneNumber, deviceUserAddress: shippingAddress, shipTo: 'DEVICE_USER' },
          deviceContacts: familyMembers,
          wifi: { wifiSsid, wifiPassword },
          optional: { additionalComments, deviceOrdererAge, deviceUserAge, deviceUserGender, deviceUserMaritalStatus, deviceUserNumberOfResidentsInHousehold },
        };
        if (enrollmentDate) {
          return navigate('/consumer-orders/thank-you');
        }
        if (formExpired) {
          return navigate(`/consumer-orders/${consumerOrderId}/expired`);
        }
        setDeviceQuantity(deviceQuantity);
        const localStorageData = localStorage.getItem(`${consumerOrderId}`);
        const parsedLocalStorageData = localStorageData ? JSON.parse(localStorageData) : null;

        if (isValidLocalStorageData(parsedLocalStorageData)) {
          setFormData(parsedLocalStorageData);
        } else {
          setFormData(apiData);
        }

        setShippedStatus(shippedDate);
        setPreferredShippingAddress(preferredShippingAddress);
        setPatientName(`${deviceUserFirstName} ${deviceUserLastName}`);
      })
      .catch(error => {
        console.log('Error: ', error);
        navigate('/');
      });
  };

  const handleIsUsingExistingPatient = (value: boolean, existingPatientId: string) => {
    setUsingExistingPatient(value);
    setExistingPatientId(existingPatientId);
  };

  const handlePatientHasExistingContacts = (value: boolean) => {
    setPatientHasExistingContacts(value);
  };

  useEffect(() => {
    getOrderData();
  }, []);

  useEffect(() => {
    if (updatedInput) {
      localStorage.setItem(`${consumerOrderId}`, JSON.stringify(formData));
    }
    setUpdatedInput(false);
  }, [updatedInput, consumerOrderId]);

  const StepComponent = steps[currentStep].component;
  return (
    <PageContainer>
      <Header />
      <ContainerWrapper>
        <ContentWrapper>
          <StepComponent
            data={formData[steps[currentStep].key]}
            onChange={(data, key = steps[currentStep].key) => handleFormDataChange(key, data)}
            next={nextStep}
            back={prevStep}
            currentStep={currentStep}
            shippedStatus={shippedStatus}
            preferredShippingAddress={preferredShippingAddress}
            allData={formData}
            isUsingExistingPatient={usingExistingPatient}
            handleIsUsingExistingPatient={handleIsUsingExistingPatient}
            existingPatientId={existingPatientId}
            patientName={patientName}
            deviceQuantity={deviceQuantity}
            handlePatientHasExistingContacts={handlePatientHasExistingContacts}
            patientHasExistingContacts={patientHasExistingContacts}
          />
        </ContentWrapper>
      </ContainerWrapper>
      <Header dark />
    </PageContainer>
  );
};

export default Purchase;
