import classNames from 'classnames';

interface NucleusControlledInputProps {
  noLabel?: boolean;
  label?: string;
  type: string;
  name: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  error?: string;
  style?: React.CSSProperties;
  required?: boolean;
}

const NucleusControlledInput: React.FC<NucleusControlledInputProps> = ({ noLabel = false, label, type, name, value, onChange, placeholder, error, style, required, ...props }) => {
  return (
    <div className="input-field row">
      <style>
        {`
            .input-field label {
            position: relative; !important;
            }
        `}
      </style>
      {!noLabel && (
        <label htmlFor={name} style={{ minWidth: '190px', marginLeft: 2 }}>
          {label + ':'}
        </label>
      )}
      <input
        style={style}
        type={type}
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={classNames(style ? '' : 'nucleus-input-mdm', ` ${error ? 'invalid' : ''}`)}
        required={required}
        {...props}
      />
      {error && <span className="helper-text" data-error={error}></span>}
    </div>
  );
};

export default NucleusControlledInput;
