import { NucleusControlledSelect, NucleusControlledWhiteBgInput } from 'components/NucleusControlledInput';
import OnBoardingGuide from 'components/OnboardingGuide/OnBoardingGuide';
import { UICard } from 'components/UI';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { ageOfPurchaserOptions, ageOfUserOptions, householdSizeOptions, maritalStatusOptions, genderOptions } from './utils';

const CardContainer = styled.div`
  display: flex;
  width: 45%;
  margin-left: 5%;
  margin-bottom: 3%;
  margin-top: 3%;
  @media (max-width: 1024px) {
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-left: 0;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 15%;
    width: 100%;
    margin-left: 0;
    margin-top: 0;
  }
`;

const OptionalQuestionsForm = ({ data, onChange, next, back, currentStep }) => {
  const {
    register,
    formState: { errors },
    clearErrors,
    setValue,
    watch,
  } = useForm({
    defaultValues: data,
  });

  useEffect(() => {
    Object.keys(data).forEach(key => {
      setValue(key, data[key]);
    });
  }, [data, setValue]);

  const handleInputChange = (name, value) => {
    onChange({ ...data, [name]: value });
  };

  return (
    <>
      <OnBoardingGuide onNext={next} back={back} currentStep={currentStep} patientName={''} />

      <CardContainer>
        <UICard
          title="Optional Questions"
          info="Your answers to these optional questions help us understand our users better, allowing us to improve our services for everyone."
          style={{
            width: '80%',
            paddingBottom: 20,
            marginBottom: '5%',
            justifyContent: 'center',
          }}
        >
          <div style={{ width: '100%', marginTop: 20 }}>
            <form>
              <NucleusControlledSelect
                label={'Age of person purchasing the device'}
                register={register('deviceOrdererAge', {
                  required: false,
                })}
                error={errors.deviceOrdererAge}
                clearErrors={clearErrors}
                setValue={setValue}
                name={'deviceOrdererAge'}
                options={ageOfPurchaserOptions}
                value={watch('deviceOrdererAge')}
                onChange={value => handleInputChange('deviceOrdererAge', value)}
                mainContainerStyle={{ marginBottom: 20 }}
              />
              <NucleusControlledSelect
                label={'Age of person using the Nucleus device'}
                labelDescription="(if different from purchaser)"
                register={register('deviceUserAge', {
                  required: false,
                })}
                error={errors.deviceUserAge}
                clearErrors={clearErrors}
                setValue={setValue}
                name={'deviceUserAge'}
                options={ageOfUserOptions}
                value={watch('deviceUserAge')}
                onChange={value => handleInputChange('deviceUserAge', value)}
                mainContainerStyle={{ marginBottom: 20 }}
              />
              <NucleusControlledSelect
                label={'Number of residents in Nucleus device user household'}
                register={register('deviceUserNumberOfResidentsInHousehold', {
                  required: false,
                })}
                error={errors.deviceUserNumberOfResidentsInHousehold}
                clearErrors={clearErrors}
                setValue={setValue}
                name={'deviceUserNumberOfResidentsInHousehold'}
                options={householdSizeOptions}
                value={watch('deviceUserNumberOfResidentsInHousehold')}
                onChange={value => handleInputChange('deviceUserNumberOfResidentsInHousehold', value)}
                mainContainerStyle={{ marginBottom: 20 }}
              />
              <NucleusControlledSelect
                label={'Marital status of Nucleus device user'}
                register={register('deviceUserMaritalStatus', {
                  required: false,
                })}
                error={errors.deviceUserMaritalStatus}
                clearErrors={clearErrors}
                setValue={setValue}
                name={'deviceUserMaritalStatus'}
                options={maritalStatusOptions}
                value={watch('deviceUserMaritalStatus')}
                onChange={value => handleInputChange('deviceUserMaritalStatus', value)}
                mainContainerStyle={{ marginBottom: 20 }}
              />
              <NucleusControlledSelect
                label={'Gender of Nucleus device user'}
                register={register('deviceUserGender', {
                  required: false,
                })}
                error={errors.deviceUserGender}
                clearErrors={clearErrors}
                setValue={setValue}
                name={'deviceUserGender'}
                options={genderOptions}
                value={watch('deviceUserGender')}
                onChange={value => handleInputChange('deviceUserGender', value)}
                mainContainerStyle={{ marginBottom: 20 }}
              />
              <NucleusControlledWhiteBgInput
                disabled={false}
                label={'Additional comments/feedback'}
                name={'additionalComments'}
                register={register('additionalComments')}
                error={errors.additionalComments}
                clearErrors={clearErrors}
                setValue={setValue}
                value={watch('additionalComments') || ''}
                onChange={e => handleInputChange('additionalComments', e.target.value)}
                inputStyle={{
                  minHeight: 50,
                }}
                containerStyle={{
                  marginBottom: 20,
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
                labelStyle={{
                  color: '#0092FF',
                }}
              />
            </form>
          </div>
          <div style={{ width: '100%', display: 'flex', justifyContent: currentStep > 0 ? 'space-between' : 'flex-end', flexDirection: 'row' }}>
            {currentStep > 0 && (
              <button onClick={back} style={{ background: 'transparent', borderStyle: 'none' }}>
                <p style={{ fontSize: 16, fontWeight: '600', margin: 0, padding: 0 }}>BACK</p>
                <div style={{ width: '100%', border: '1px solid #0A313F' }} />
              </button>
            )}
            <button style={{ background: 'transparent', borderStyle: 'none', color: '#0A313F' }} onClick={next}>
              <p style={{ fontSize: 16, fontWeight: '600', color: '#0A313F', margin: 0, padding: 0 }}>{currentStep === 4 ? 'REVIEW' : 'NEXT'}</p>
              <div style={{ width: '100%', border: '1px solid #0A313F' }} />
            </button>
          </div>
        </UICard>
      </CardContainer>
    </>
  );
};

export default OptionalQuestionsForm;
