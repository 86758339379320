import AutoCompleteAddress from 'components/AutoCompleteAddress/AutoCompleteAddress';
import { NucleusControlledWhiteBgInput } from 'components/NucleusControlledInput';
import OnBoardingGuide from 'components/OnboardingGuide/OnBoardingGuide';
import { UICard } from 'components/UI';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

const CardContainer = styled.div`
  display: flex;
  width: 45%;
  margin-left: 5%;
  margin-top: 3%;
  margin-bottom: 3%;
  @media (max-width: 1024px) {
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-left: 0;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-left: 0;
    margin-top: 0;
  }
`;
const WelcomeHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  padding-right: 5%;
  justify-content: center;
  align-items: center;
`;
const WelcomeTitle = styled.p`
  color: #0a313f;
  font-size: 26px;
  font-weight: 600;
  text-align: center;
  width: 80%;
  line-height: 25px;
  @media (max-width: 768px) {
    font-size: 20px;
  }
  @media (min-width: 1025px) {
    font-size: 24px;
  }
`;
const LinkTo = styled.a`
  color: #0092ff;
  text-decoration: none;
  text-decoration: underline;
`;
const WelcomeText = styled.p`
  color: #0a313f;
  font-size: 20px;
  font-weight: 300;
  width: 80%;
  line-height: 25px;
  @media (max-width: 768px) {
    text-align: center;
    margin: 0 auto;
    font-size: 16px;
  }
  @media (min-width: 1025px) {
    font-size: 24px;
  }
`;

const OrdererForm = ({ allData, data, onChange, next, back, currentStep, shippedStatus }) => {
  const {
    register,
    formState: { errors },
    clearErrors,
    setValue,
    trigger,
  } = useForm({ defaultValues: data });

  useEffect(() => {
    if (data) {
      Object.keys(data).forEach(key => {
        setValue(key, data[key]);
      });
    }
  }, [data, setValue]);

  const handleInputChange = (name, value) => {
    onChange({ ...data, [name]: value });
  };
  const handleOnChangeShipTo = value => {
    onChange({ deviceUser: allData.modifiedAddress?.deviceUser, deviceOrderer: value }, ['modifiedAddress']);
  };

  const onNext = async () => {
    const isValid = await trigger();
    if (isValid) {
      next();
    }
  };

  const tooltipContent = (
    <>
      Your phone number can be <br />
      updated by contacting our <br />
      customer service team at <br />
      <LinkTo href="mailto:support@nucleuscare.com">support@nucleuscare.com</LinkTo>
      <br />
      or <br />
      <LinkTo href="tel:5169511340">(516) 951-1340</LinkTo>
    </>
  );

  return (
    <>
      <WelcomeHeader>
        <WelcomeTitle>Welcome!</WelcomeTitle>
        <WelcomeText>
          Complete the form to confirm your information and personalize your Nucleus device before it is shipped. If you have any questions, feel free to reach out to our customer
          service team at <LinkTo href="mailto:support@nucleuscare.com">support@nucleuscare.com</LinkTo> or call us at <LinkTo href="tel:5169511340">(516) 951-1340</LinkTo>. We're
          happy to help!
        </WelcomeText>
      </WelcomeHeader>
      <OnBoardingGuide onNext={onNext} back={back} currentStep={currentStep} patientName={''} />
      <CardContainer>
        <UICard
          title="Device Orderer"
          info="This is the person purchasing the product"
          style={{
            width: '80%',
            paddingBottom: 20,
          }}
        >
          <div style={{ width: '100%', marginTop: 20 }}>
            <form>
              <NucleusControlledWhiteBgInput
                disabled={false}
                required
                label={'First name'}
                name={'deviceOrdererFirstName'}
                register={register('deviceOrdererFirstName', { required: '*Error - this field is required' })}
                error={errors.deviceOrdererFirstName}
                clearErrors={clearErrors}
                setValue={setValue}
                value={data.deviceOrdererFirstName || ''}
                onChange={e => handleInputChange('deviceOrdererFirstName', e.target.value)}
                inputStyle={{
                  minHeight: 50,
                }}
                containerStyle={{
                  marginBottom: 20,
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
                labelStyle={{
                  color: '#0092FF',
                }}
              />
              <NucleusControlledWhiteBgInput
                disabled={false}
                required
                label={'Last name'}
                name={'deviceOrdererLastName'}
                register={register('deviceOrdererLastName', { required: '*Error - this field is required' })}
                error={errors.deviceOrdererLastName}
                clearErrors={clearErrors}
                setValue={setValue}
                value={data.deviceOrdererLastName || ''}
                onChange={e => handleInputChange('deviceOrdererLastName', e.target.value)}
                inputStyle={{
                  minHeight: 50,
                }}
                containerStyle={{
                  marginBottom: 20,
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
                labelStyle={{
                  color: '#0092FF',
                }}
              />
              <NucleusControlledWhiteBgInput
                required
                tooltip
                tooltipContent={tooltipContent}
                disabled={true}
                label={'Phone number'}
                name={'deviceOrdererPhoneNumber'}
                register={register('deviceOrdererPhoneNumber')}
                error={errors.deviceOrdererPhoneNumber}
                clearErrors={clearErrors}
                setValue={setValue}
                value={data.deviceOrdererPhoneNumber || ''}
                onChange={e => handleInputChange('deviceOrdererPhoneNumber', e.target.value)}
                inputStyle={{
                  minHeight: 50,
                }}
                containerStyle={{
                  marginBottom: 20,
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
                labelStyle={{
                  color: '#0092FF',
                }}
              />
              <AutoCompleteAddress
                onChangeShipTo={handleOnChangeShipTo}
                disabled={shippedStatus}
                required
                label={'Address'}
                name={'deviceOrdererAddress'}
                register={register('deviceOrdererAddress', { required: '*Error - this field is required' })}
                error={errors.deviceOrdererAddress}
                clearErrors={clearErrors}
                setValue={setValue}
                value={data.deviceOrdererAddress || ''}
                onChange={e => handleInputChange('deviceOrdererAddress', e.target.value)}
              />
              <NucleusControlledWhiteBgInput
                disabled={false}
                label={'Email'}
                name={'deviceOrdererEmail'}
                register={register('deviceOrdererEmail', {
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: '*Error - invalid email',
                  },
                })}
                error={errors.deviceOrdererEmail}
                clearErrors={clearErrors}
                setValue={setValue}
                value={data.deviceOrdererEmail || ''}
                onChange={e => handleInputChange('deviceOrdererEmail', e.target.value)}
                inputStyle={{
                  minHeight: 50,
                }}
                containerStyle={{
                  marginBottom: 20,
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
                labelStyle={{
                  color: '#0092FF',
                }}
              />
            </form>
          </div>
          <div style={{ width: '100%', display: 'flex', justifyContent: currentStep > 0 ? 'space-between' : 'flex-end', flexDirection: 'row' }}>
            {currentStep > 0 && (
              <button onClick={back}>
                <p style={{ fontSize: 16, fontWeight: '600', color: '#0A313F' }}>BACK</p>
              </button>
            )}
            <button style={{ background: 'transparent', borderStyle: 'none' }} onClick={onNext}>
              <p style={{ fontSize: 16, fontWeight: '600', color: '#0A313F', margin: 0, padding: 0 }}>NEXT</p>
              <div style={{ width: '100%', border: '1px solid #0A313F' }} />
            </button>
          </div>
        </UICard>
      </CardContainer>
    </>
  );
};

export default OrdererForm;
