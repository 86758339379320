import NucleuscareBackendClient from '@nucleus-care/nucleuscare-backend-client';
import { LoadScript } from '@react-google-maps/api';
import ExpiredLink from 'pages/ExpiredLink/ExpiredLink';
import Home from 'pages/Home/Home';
import Purchase from 'pages/Purchase/Purchase';
import Success from 'pages/Success/Success';
import ThankYou from 'pages/ThankYou/ThankYou';
import { Provider } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import BackendClientLauncher from 'utils/BackendClientLauncher';
import './App.css';

function App() {
  return (
    <Provider store={NucleuscareBackendClient}>
      <LoadScript googleMapsApiKey={String(process.env.REACT_APP_GOOGLE_MAPS_API_KEY)} libraries={['places']}>
        <BackendClientLauncher />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/consumer-orders/:consumerOrderId/expired" element={<ExpiredLink />} />
          <Route path="/consumer-orders/:consumerOrderId" element={<Purchase />} />
          <Route path="/success" element={<Success />} />
          <Route path="/consumer-orders/thank-you" element={<ThankYou />} />
          <Route path="*" element={<Navigate to={'/'} />} />
        </Routes>
      </LoadScript>
    </Provider>
  );
}

export default App;
