import { setDevEnv, setTestEnv, setProdEnv, setLocalEnv } from '@nucleus-care/nucleuscare-backend-client';
import { useEffect } from 'react';

import {
  useDispatch,
  //DefaultRootState
} from 'react-redux';

const BackendClientLauncher = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    switch (window.location.hostname) {
      case 'localhost':
        dispatch(setLocalEnv());
        break;
      case 'order-info-dev.nucleuscare.com':
        dispatch(setDevEnv());
        break;
      case 'order-info-test.nucleuscare.com':
        dispatch(setTestEnv());
        break;
      default:
        dispatch(setProdEnv());
        break;
    }
  }, []);

  return null;
};

export default BackendClientLauncher;
