import React from 'react';
import { FieldError, FieldErrorsImpl, FieldValues, Merge, UseFormClearErrors, UseFormRegisterReturn, UseFormSetValue } from 'react-hook-form';
import styled from 'styled-components';
interface Option {
  value: string | number;
  label: string;
}
interface NucleusControlledSelectProps {
  register?: UseFormRegisterReturn;
  name: string;
  id?: string;
  label?: string;
  labelDescription?: string;
  options: Option[];
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  clearErrors?: UseFormClearErrors<FieldValues>;
  setValue?: UseFormSetValue<FieldValues>;
  valueAccessor?: string;
  labelAccessor?: string;
  loadingOptions?: boolean;
  noLabel?: boolean;
  mainContainerStyle?: React.CSSProperties;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  [key: string]: unknown;
}

const NucleusControlledSelect: React.FC<NucleusControlledSelectProps> = ({
  register,
  name,
  id,
  label,
  labelDescription,
  options,
  error,
  clearErrors,
  setValue,
  valueAccessor = 'value',
  labelAccessor = 'label',
  loadingOptions,
  noLabel = false,
  mainContainerStyle,
  onChange,
  ...props
}) => {
  const handleSelectChange = e => {
    const value = e.target.value;
    if (error) {
      clearErrors?.(name);
    }
    onChange(value);
    if (setValue) {
      setValue(name, value);
    }
  };

  const Option = styled.option`
    font-size: 16px;
  `;
  return (
    <React.Fragment>
      {!noLabel && (
        <div style={{ marginBottom: '12px', width: '100%' }}>
          <label
            style={{
              minWidth: 130,
              textAlign: 'start',
              fontSize: 16,
              color: '#0092FF',
              fontWeight: '600',
            }}
            htmlFor={name}
            id={id}
          >
            {label + ':'}
          </label>
          {labelDescription && <label style={{ fontWeight: '300', fontSize: 12, color: '#0092FF' }}>{labelDescription}</label>}
        </div>
      )}
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          minHeight: 50,
          opacity: props?.disabled ? 0.4 : 1,
          backgroundColor: '#fff',
          borderRadius: 5,
          border: error ? '1px solid #FE3824' : '1px solid #0092ff',
          ...mainContainerStyle,
        }}
      >
        <select
          style={{
            width: '100%',
            height: '50px',
            display: 'block',
            borderRadius: 5,
            border: 'none',
            outline: 'none',
            opacity: props?.disabled ? 0.5 : 1,
          }}
          name={name}
          id={id}
          {...props}
          {...register}
          onChange={handleSelectChange}
        >
          <Option className="select-option" value={''}>
            Select
          </Option>
          {!loadingOptions &&
            options.map(option => (
              <Option className="select-option" key={option[valueAccessor] as string | number} value={option[valueAccessor] as string | number}>
                {option[labelAccessor] as string}
              </Option>
            ))}
        </select>
      </div>
      {error && (
        <p
          // PLEASE DO NOT MODIFY THIS STYLES
          style={{
            color: '#FE3824',
            fontWeight: 'bold',
            marginTop: 5,
            textAlign: 'end',
          }}
        >
          {typeof error?.message === 'string' ? error.message : ''}
        </p>
      )}
    </React.Fragment>
  );
};

export default NucleusControlledSelect;
