import axios from 'axios';
export const authApi = axios.create({
  baseURL: process.env.REACT_APP_AUTH_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const cApi = axios.create({
  baseURL: process.env.REACT_APP_CBACKEND_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'X-EntityType': 'Control',
    'X-Secret': process.env.REACT_APP_X_SECRET,
  },
});

export const setCAuthorizationToken = token => {
  if (token) {
    cApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete cApi.defaults.headers.common['Authorization'];
  }
};
