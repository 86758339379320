import styled from 'styled-components';
import ArrowAsset from './assets/arrow.png';

const GuideContainer = styled.div<{ currentStep: number }>`
  max-width: 1440px;
  width: 36%;
  height: 143px;
  border-radius: 5px;
  margin-bottom: 5%;
  margin-top: 3%;
  background-color: #ffca20;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 4px 4px 4px 0px #00000040;
  padding-top: 10px;
  padding-bottom: 10px;
  position: absolute;
  left: 50%;
  top: ${({ currentStep }) => {
    if (currentStep === 0) {
      return '480px';
    }
    return '210px';
  }};
  @media (min-width: 1440px) {
    left: 45%;
    max-width: 650px;
  }
  @media (max-width: 1024px) {
    width: 36%;
    position: initial;
  }

  @media (max-width: 767px) {
    width: 88%;
    position: initial;
  }
  @media (min-width: 1700px) {
    left: 40%;
    max-width: 650px;
  }
  @media (min-width: 1441px) {
    max-width: 650px;
    left: 50%;
  }
`;
const OnBoardingContainer = styled.div`
  display: flex;
  max-width: 1440px;
  width: 100%;
  justify-content: center;
`;
const GuideText = styled.p`
  color: #0a313f;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  width: 80%;
  line-height: 25px;
`;
const StepsContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;
const ActiveStepContainer = styled.div`
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;
const Arrow = styled.img<{ currentStep: number }>`
  position: absolute;
  top: ${({ currentStep }) => {
    if (currentStep === 0) {
      return '380px';
    }
    return '120px';
  }};
  @media (max-width: 1024px) {
    display: none;
  }
  @media (max-width: 767px) {
    display: none;
  }
  @media (min-width: 1025px) {
    margin-left: 110px;
  }
  @media (min-width: 1440px) {
    margin-left: 30px;
  }
  @media (min-width: 1441px) {
    margin-left: 130px;
    margin-left: ${({ currentStep }) => {
      if (currentStep === 2) {
        return '210px';
      }
      return '130px';
    }};
  }
`;
interface Step {
  text: string;
  key: string;
}

interface OnBoardingGuideProps {
  currentStep: number;
  onNext: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  back: () => void;
  patientName: string;
}
const getStepsText = (patientName: string): Step[] => [
  { text: 'Fill out this section with the details of the person who is purchasing the Nucleus device', key: 'orderer' },
  { text: 'Fill out this section with the details of the person who will be using the Nucleus device', key: 'deviceUser' },
  { text: `A link to download the Nucleus InTouch App will be sent to all "Device Contacts" so they can call ${patientName}`, key: 'deviceContacts' },
  { text: 'To speed things up, you can connect the device to its destination internet', key: 'wifi' },
  { text: 'Optional questions to help us understand our users better.', key: 'optional' },
];
const steps = [0, 1, 2, 3, 4];

const StepIndicator = styled.div<{ isActive: boolean }>`
  width: ${({ isActive }) => (isActive ? 10 : 6)}px;
  height: ${({ isActive }) => (isActive ? 10 : 6)}px;
  border-radius: 10px;
  background-color: ${({ isActive }) => (isActive ? '#0A313F' : '#ffffff')};
  margin: 0 4px;
`;
const OnBoardingGuide: React.FC<OnBoardingGuideProps> = ({ currentStep, onNext, back, patientName }) => {
  const stepsText = getStepsText(patientName);
  return (
    <OnBoardingContainer>
      <Arrow currentStep={currentStep} src={ArrowAsset} />
      <GuideContainer currentStep={currentStep}>
        <GuideText>{stepsText[currentStep].text}</GuideText>
        <StepsContainer>
          {currentStep > 0 ? (
            <button onClick={back} style={{ background: 'transparent', borderStyle: 'none' }}>
              <p style={{ color: '#0A313F', fontSize: 13, fontWeight: '600' }}>GO BACK</p>
            </button>
          ) : (
            <div style={{ width: '14%' }} />
          )}
          <ActiveStepContainer>
            {steps.map(step => (
              <StepIndicator key={step} isActive={currentStep === step} />
            ))}
          </ActiveStepContainer>
          <button onClick={onNext} style={{ background: 'transparent', borderStyle: 'none' }}>
            <p style={{ color: '#0A313F', fontSize: 13, fontWeight: '600' }}>{currentStep === 4 ? 'REVIEW' : 'NEXT'}</p>
          </button>
        </StepsContainer>
      </GuideContainer>
    </OnBoardingContainer>
  );
};

export default OnBoardingGuide;
