import styled from 'styled-components';

interface Steps {
  StepOne: string;
  StepTwo: string;
  StepThree: string;
  StepFour: string;
  StepFive: string;
  StepSix: string;
}

interface ContactAlternateInstructionsProps {
  steps: Steps;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: white;
  padding: 20px;
`;

const Title = styled.p`
  color: #f7090d;
  font-weight: 600;
  font-size: 16px;
`;

const Instruction = styled.div`
  display: flex;
  align-items: flex-start;
  font-style: italic;
  color: #0a313f;
  font-weight: 300;
  font-size: 16px;
  margin-bottom: -10px;
  padding-left: 10px;
`;

const StepNumber = styled.p`
  font-size: 10px;
  margin: 0 8px 0 0;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

const ContactsImage = styled.img`
  max-width: 100%;
  height: 381px;
  @media (max-width: 1440px) {
    width: 520px;
    height: 330px;
  }
  @media (max-width: 1024px) {
    width: 820px;
    height: 570px;
  }
  @media (max-width: 768px) {
    width: 615px;
    height: 410px;
  }
  @media (max-width: 430px) {
    width: 320px;
    height: 210px;
  }
  @media (max-width: 320px) {
    width: 250px;
    height: 150px;
  }
`;

const ContactsImageSmall = styled.img`
  width: 217px;
  height: 469px;
  @media (max-width: 430px) {
    width: 92px;
    height: 200px;
  }
`;

const ContactAlteranateInstructions = ({ steps }: ContactAlternateInstructionsProps) => (
  <Container>
    <Title>To add a contact on the Nucleus device:</Title>
    <Instruction>
      <StepNumber>1.</StepNumber>
      <span>Press on the “add contact” button as pictured below</span>
    </Instruction>
    <ImageWrapper>
      <ContactsImage src={steps.StepOne} />
    </ImageWrapper>

    <Instruction>
      <StepNumber>2.</StepNumber>
      <span>
        Follow the instructions on the next screen (below). The new contact will receive a text message with instructions to download the InTouch app and finish setting up their
        profile.
      </span>
    </Instruction>
    <ImageWrapper>
      <ContactsImage src={steps.StepTwo} />
    </ImageWrapper>

    <Title>To add a contact on the InTouch app:</Title>

    <Instruction>
      <StepNumber>1.</StepNumber>
      <span>Go to "more family"</span>
    </Instruction>

    <Instruction>
      <StepNumber>2.</StepNumber>
      <span>Press the yellow button at the bottom to add a new contact</span>
    </Instruction>
    <ImageWrapper>
      <ContactsImageSmall src={steps.StepThree} />
    </ImageWrapper>

    <Instruction>
      <StepNumber>3.</StepNumber>
      <span>Invite a new contact manually or choose someone from your phone contacts</span>
    </Instruction>
    <ImageWrapper>
      <ContactsImageSmall src={steps.StepFour} />
    </ImageWrapper>

    <Instruction>
      <StepNumber>4.</StepNumber>
      <span>Enter their info and press "Invite Family Member"</span>
    </Instruction>
    <ImageWrapper>
      <ContactsImageSmall src={steps.StepFive} />
      <ContactsImageSmall src={steps.StepSix} />
    </ImageWrapper>

    <Instruction style={{ marginBottom: 10 }}>
      <StepNumber>5.</StepNumber>
      <span>The new contact will receive a text message with instructions to download the InTouch app and finish setting up their profile.</span>
    </Instruction>
  </Container>
);

export default ContactAlteranateInstructions;
