import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NucleusControlledWhiteBgInput } from 'components/NucleusControlledInput';
import OnBoardingGuide from 'components/OnboardingGuide/OnBoardingGuide';
import { UICard, UIModal } from 'components/UI';
import { ConsumerOrderData } from 'pages/Purchase/Purchase';
import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import styled from 'styled-components';
import 'react-phone-input-2/lib/style.css';
import { cApi } from 'utils/api/httpClients';
import StepOne from './assets/1.png';
import StepTwo from './assets/2.png';
import StepThree from './assets/3.png';
import StepFour from './assets/4.png';
import StepFive from './assets/5.png';
import StepSix from './assets/6.png';
import ContactsImageMain from './assets/device-contacts.png';
import ContactAlteranateInstructions from './components/ContactAlternateInstructions';
const steps = {
  StepOne,
  StepTwo,
  StepThree,
  StepFour,
  StepFive,
  StepSix,
};
const CardContainer = styled.div`
  display: flex;
  width: 45%;
  margin-left: 5%;
  margin-top: 3%;
  margin-bottom: 3%;
  @media (max-width: 1024px) {
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-left: 0;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 3%;
  }
  @media (min-width: 1441px) {
    margin-top: 3%;
    margin-bottom: 3%;
    flex-direction: row;
    justify-content: center;
  }
`;

const InputsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const InputContainer = styled.div`
  width: 48%;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  min-height: 30px;
  border: 1px solid #0092ff;
  width: 94%;
  @media (max-width: 768px) {
    margin-top: 0%;
    margin-bottom: 5%;
  }
`;

const ToggleLabel = styled.label`
  color: #0092ff;
  font-size: 16px;
`;

const TooltipIcon = styled.span`
  display: inline-block;
  margin-left: 5px;
  color: #0092ff;
  cursor: pointer;
  font-size: 16px;
`;

const ToggleInput = styled.input`
  position: relative;
  width: 50px; /* Width of the toggle switch */
  height: 30px; /* Height of the toggle switch */
  -webkit-appearance: none;
  appearance: none;
  background-color: #ebf2fe;
  outline: none;
  border-radius: 20px; /* Rounded corners of the toggle switch */
  transition: background-color 0.3s;
  cursor: pointer;

  &:checked {
    background-color: #0092ff; /* Background color when checked */
  }

  &:before {
    content: '';
    position: absolute;
    width: 16px; /* Width of the toggle circle */
    height: 16px; /* Height of the toggle circle */
    border-radius: 50%; /* Make it a circle */
    background-color: #b4c6d3;
    transition: transform 0.3s;
    transform: translateX(2px); /* Adjust based on initial position */
    top: 7px; /* Vertically centers the circle */
    left: 3px;
  }

  &:checked:before {
    transform: translateX(26px); /* Move circle to the right end */
  }
`;

const FloatingButton = styled.button`
  position: absolute;
  right: -15px;
  bottom: 60px;
  width: 50px;
  height: 50px;
  background-color: #ffca20;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:after {
    content: '+';
    font-size: 45px;
    color: white;
  }
`;

const ModalContentContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

// Title text
const TitleText = styled.p`
  text-align: center;
  color: #0a313f;
  font-size: 20px;
  font-weight: 400;
`;

const PhoneInputDescriptionLabel = styled.p`
  color: #0a313f;
  font-size: 12px;
  margin-top: -15px;
  font-weight: 400;
  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

// Main message text
const MainMessageText = styled.p`
  text-align: center;
  color: #0a313f;
  font-size: 24px;
  font-weight: 600;
`;

// Additional information text
const InfoText = styled.p`
  text-align: center;
  color: #0a313f;
  font-size: 20px;
  font-weight: 400;
`;

const ContactsImage = styled.img`
  max-width: 90%;
  height: 381px;
  object-fit: contain;
  @media (min-width: 1440px) {
    height: 330px;
    width: 1200px;
  }
  @media (max-width: 1440px) {
    height: 330px;
  }
  @media (max-width: 1024px) {
    height: 570px;
  }
  @media (max-width: 768px) {
    height: 410px;
  }
  @media (max-width: 430px) {
    height: 210px;
  }
  @media (max-width: 320px) {
    height: 150px;
  }
`;

interface Contact {
  telephone: string;
  firstName: string;
  lastName: string;
  admin: boolean;
}

interface DeviceContactsFormProps {
  next: () => void;
  back: () => void;
  currentStep: number;
  data: Contact[];
  onChange: (contacts: Contact[]) => void;
  patientName: string;
  allData?: ConsumerOrderData;
  patientHasExistingContacts?: boolean;
}

interface UpdateContactData {
  firstName: string;
  lastName: string;
}

const DeviceContactsForm: React.FC<DeviceContactsFormProps> = ({ next, back, currentStep, data, onChange, allData, patientHasExistingContacts }) => {
  const {
    register,
    formState: { errors },
    clearErrors,
    setValue,
    control,
    watch,
    trigger,
    getValues,
  } = useForm<{ contacts: Contact[] }>({
    defaultValues: {
      contacts: [{ telephone: '', firstName: '', lastName: '', admin: false }],
    },
  });
  const [phoneErrors, setPhoneErrors] = useState<{ [index: number]: string }>({});
  const [validatePhone, setValidatePhone] = useState<boolean>(false);
  const [phoneToValidate, setPhoneToValidate] = useState<string>('');
  const [showExistingModal, setShowExistingModal] = useState<boolean>(false);
  const [indexToUpdate, setIndexToUpdate] = useState<number | null>(null);
  const [updateContactData, setUpdateContactData] = useState<UpdateContactData>({ firstName: '', lastName: '' });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contacts',
  });

  const appendContact = (index: number, contact: Contact, allData: ConsumerOrderData) => {
    if (index === 0) {
      append({
        admin: false,
        firstName: allData?.orderer?.deviceOrdererFirstName || '',
        lastName: allData?.orderer?.deviceOrdererLastName || '',
        telephone: '',
      });
    } else {
      append(contact);
    }
  };

  const addContact = () => {
    append({ telephone: '', firstName: '', lastName: '', admin: false });
  };

  const onNext = async () => {
    const isValid = await trigger();
    if (isValid) {
      next();
    }
  };

  const setContactValues = (index: number, contact: Contact) => {
    setValue(`contacts.${index}.telephone`, contact.telephone || '');
    setValue(`contacts.${index}.firstName`, contact.firstName || '');
    setValue(`contacts.${index}.lastName`, contact.lastName || '');
    setValue(`contacts.${index}.admin`, contact.admin || false);
  };

  useEffect(() => {
    if (Array.isArray(data) && data.length === 0 && allData) {
      const contact = {
        admin: !!allData?.orderer?.deviceOrdererFirstName || true,
        firstName: allData?.orderer?.deviceOrdererFirstName || '',
        lastName: allData?.orderer?.deviceOrdererLastName || '',
        telephone: allData?.orderer?.deviceOrdererPhoneNumber || '',
      };
      setContactValues(0, contact);
      onChange([contact]);
      if (allData?.orderer?.deviceOrdererPhoneNumber) {
        setValidatePhone(true);
        setPhoneToValidate(allData?.orderer?.deviceOrdererPhoneNumber);
        setIndexToUpdate(0);
      }
    } else if (Array.isArray(data)) {
      data.forEach((contact, index) => {
        if (fields[index]) {
          setContactValues(index, contact);
        } else if (allData) {
          appendContact(index, contact, allData);
        }
      });
    }
  }, []);

  const onRemoveContact = (index: number) => {
    if (fields.length === 1 && index === 0) {
      const contact = {
        admin: false,
        firstName: '',
        lastName: '',
        telephone: '',
      };
      setContactValues(0, contact);
    } else {
      remove(index);
    }
  };
  const checkAndRemoveEmptyContacts = () => {
    const contacts = watch('contacts') as Contact[];
    const filteredContacts = contacts.filter(contact => contact.telephone || contact.firstName || contact.lastName);
    onChange(filteredContacts);
  };
  useEffect(() => {
    const filteredContacts = watch('contacts')?.filter(contact => contact !== undefined) as Contact[];
    if (filteredContacts?.length === 0) {
      const contact = {
        admin: !!allData?.orderer?.deviceOrdererFirstName || true,
        firstName: allData?.orderer?.deviceOrdererFirstName || '',
        lastName: allData?.orderer?.deviceOrdererLastName || '',
        telephone: allData?.orderer?.deviceOrdererPhoneNumber || '',
      };
      setContactValues(0, contact);
      onChange([contact]);
      if (allData?.orderer?.deviceOrdererPhoneNumber) {
        setValidatePhone(true);
        setPhoneToValidate(allData?.orderer?.deviceOrdererPhoneNumber);
        setIndexToUpdate(0);
      }
    } else {
      onChange(filteredContacts);
    }
    return () => {
      checkAndRemoveEmptyContacts();
    };
  }, [watch('contacts')]);

  const isValidPhoneNumber = phone => {
    const phoneRegex = /^(\+\d{1,3})?[\s.-]?\(?\d{1,4}\)?[\s.-]?\d{1,4}[\s.-]?\d{1,4}[\s.-]?\d{1,9}$/;
    if (phone.length < 10) {
      return false;
    } else {
      return phoneRegex.test(phone);
    }
  };

  const closeExistingModal = () => {
    if (indexToUpdate !== null && indexToUpdate !== undefined) {
      setValue(`contacts.${indexToUpdate}.telephone`, '');
      setValue(`contacts.${indexToUpdate}.firstName`, '');
      setValue(`contacts.${indexToUpdate}.lastName`, '');
    }
    setShowExistingModal(false);
    setPhoneToValidate('');
    setUpdateContactData({ firstName: '', lastName: '' });
    setIndexToUpdate(null);
  };

  const addExistingContact = () => {
    if (indexToUpdate !== null && indexToUpdate !== undefined) {
      setValue(`contacts.${indexToUpdate}.firstName`, updateContactData.firstName);
      setValue(`contacts.${indexToUpdate}.lastName`, updateContactData.lastName);
    }
    setPhoneToValidate('');
    setShowExistingModal(false);
    setUpdateContactData({ firstName: '', lastName: '' });
    setIndexToUpdate(null);
  };

  const tooltipContent = (
    <>
      <p>Giving Admin status to a contact will enable them to </p>
      <ul style={{ padding: '10px', margin: '0', color: '#fff', listStyleType: 'disc' }}>
        <li>Invite new contacts</li>
        <li>Remove existing contacts</li>
        <li>Set the drop-in feature for each contact</li>
      </ul>
    </>
  );
  useEffect(() => {
    if (validatePhone) {
      const data = new URLSearchParams();
      data.append('Telephone', `+${phoneToValidate}`);
      cApi
        .post('FamilyMember.asmx/IsAlreadyExists', data)
        .then(response => {
          if (response.data.DoesExists) {
            setUpdateContactData({ firstName: response.data.FirstName, lastName: response.data.LastName });
            setShowExistingModal(true);
          }
        })
        .catch(error => {
          console.log('Error: ', error);
        });
    }
    setValidatePhone(false);
  }, [validatePhone]);
  return (
    <>
      <OnBoardingGuide
        onNext={onNext}
        back={back}
        currentStep={currentStep}
        patientName={`${allData?.deviceUser?.deviceUserFirstName} ${allData?.deviceUser?.deviceUserLastName}`}
      />
      <CardContainer>
        <UICard
          title="Device Contacts"
          info={
            patientHasExistingContacts
              ? 'The Device User you entered already exists within our system. Please follow these steps to add new contacts on the Nucleus Device and/or the InTouch app.'
              : `Invite family and friends to be a contact on ${allData?.deviceUser?.deviceUserFirstName} ${allData?.deviceUser?.deviceUserLastName}’s device.`
          }
          style={{
            backgroundColor: '#FFFFFF',
            width: '80%',
            paddingBottom: 20,
            marginBottom: '5%',
          }}
        >
          {!patientHasExistingContacts && (
            <>
              <p
                style={{ fontWeight: 300, fontSize: 16, fontStyle: 'italic' }}
              >{`${allData?.deviceUser?.deviceUserFirstName} ${allData?.deviceUser?.deviceUserLastName} will be able to call any of them with a simple tap on the screen, as shown below.`}</p>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                <ContactsImage src={ContactsImageMain} />
              </div>
              <div style={{ width: '100%', marginTop: 20 }}>
                <form>
                  {fields.map((field, index) => (
                    <div key={field.id}>
                      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <p style={{ fontWeight: 'bold' }}>{index + 1}.</p>
                        <button type="button" onClick={() => onRemoveContact(index)} style={{ background: 'transparent', borderStyle: 'none' }}>
                          <p style={{ color: '#FE3824', fontWeight: 'bold' }}>Remove</p>
                        </button>
                      </div>
                      <InputsContainer>
                        <InputContainer>
                          <Controller
                            control={control}
                            name={`contacts.${index}.telephone`}
                            rules={{
                              validate: value => {
                                const firstName = getValues(`contacts.${index}.firstName`);
                                const lastName = getValues(`contacts.${index}.lastName`);
                                if ((firstName || lastName) && !value) {
                                  setPhoneErrors(prev => ({ ...prev, [index]: 'Phone number is required if first name or/and last name is provided' }));
                                  return 'Phone number is required if name is added';
                                } else if (value && !isValidPhoneNumber(value)) {
                                  setPhoneErrors(prev => ({ ...prev, [index]: 'Invalid phone number format' }));
                                  return 'Invalid phone number format';
                                }
                                setPhoneErrors(prev => ({ ...prev, [index]: '' }));
                                return true;
                              },
                            }}
                            render={({ field }) => (
                              <>
                                <PhoneInput
                                  country={'us'}
                                  value={field.value}
                                  onChange={phone => field.onChange(phone)}
                                  onBlur={() => {
                                    setValidatePhone(true);
                                    setPhoneToValidate(field.value);
                                    setIndexToUpdate(index);
                                  }}
                                  inputStyle={{
                                    width: '100%',
                                    minHeight: 50,
                                    touchAction: 'manipulation',
                                  }}
                                  containerStyle={{
                                    marginBottom: 20,
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    border: '1px solid #0092FF',
                                    borderRadius: 5,
                                  }}
                                  specialLabel="Cellphone number"
                                />
                                <PhoneInputDescriptionLabel>Click the country code dropdown for more options</PhoneInputDescriptionLabel>
                                {phoneErrors[index] && <p style={{ color: '#FE3824', fontWeight: '700', fontSize: 10 }}>{phoneErrors[index]}</p>}
                              </>
                            )}
                          />
                        </InputContainer>
                        <InputContainer>
                          <ToggleContainer>
                            <ToggleLabel htmlFor={`contacts.${index}.makeAdmin`}>
                              Make Admin
                              <TooltipIcon data-tooltip-id={`tooltip-${index}`}>
                                <FontAwesomeIcon icon={faCircleInfo} />
                              </TooltipIcon>
                              <ReactTooltip id={`tooltip-${index}`} place="bottom" style={{ backgroundColor: '#0A313F' }}>
                                {tooltipContent}
                              </ReactTooltip>
                            </ToggleLabel>
                            <ToggleInput type="checkbox" id={`contacts.${index}.makeAdmin`} {...register(`contacts.${index}.admin`)} />
                          </ToggleContainer>
                        </InputContainer>
                      </InputsContainer>
                      <InputsContainer>
                        <InputContainer>
                          <NucleusControlledWhiteBgInput
                            disabled={false}
                            required={!!getValues(`contacts.${index}.telephone`)}
                            label="First name"
                            name={`contacts.${index}.firstName`}
                            register={register(`contacts.${index}.firstName`, {
                              validate: value => {
                                if (getValues(`contacts.${index}.telephone`) && !value) {
                                  return 'First name is required if phone number is provided';
                                }
                                return true;
                              },
                            })}
                            error={errors.contacts?.[index]?.firstName}
                            clearErrors={clearErrors}
                            setValue={setValue}
                            inputStyle={{
                              minHeight: 50,
                            }}
                            // //alternateBorder
                            containerStyle={{
                              marginBottom: 20,
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                            }}
                            labelStyle={{
                              color: '#0092FF',
                            }}
                          />
                        </InputContainer>

                        <InputContainer>
                          <NucleusControlledWhiteBgInput
                            disabled={false}
                            required={!!getValues(`contacts.${index}.telephone`)}
                            label="Last name"
                            name={`contacts.${index}.lastName`}
                            register={register(`contacts.${index}.lastName`, {
                              validate: value => {
                                if (getValues(`contacts.${index}.telephone`) && !value) {
                                  return 'Last name is required if phone number is provided';
                                }
                                return true;
                              },
                            })}
                            error={errors.contacts?.[index]?.lastName}
                            clearErrors={clearErrors}
                            setValue={setValue}
                            inputStyle={{
                              minHeight: 50,
                            }}
                            //alternateBorder
                            containerStyle={{
                              marginBottom: 40,
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                            }}
                            labelStyle={{
                              color: '#0092FF',
                            }}
                          />
                        </InputContainer>
                      </InputsContainer>
                    </div>
                  ))}
                  <FloatingButton type="button" onClick={addContact} />
                </form>
              </div>
            </>
          )}
          {patientHasExistingContacts && <ContactAlteranateInstructions steps={steps} />}
          <div style={{ width: '100%', display: 'flex', justifyContent: currentStep > 0 ? 'space-between' : 'flex-end', flexDirection: 'row' }}>
            {currentStep > 0 && (
              <button type="button" style={{ background: 'transparent', borderStyle: 'none' }} onClick={back}>
                <p style={{ fontSize: 16, fontWeight: '600', color: '#0A313F', margin: 0, padding: 0 }}>BACK</p>
                <div style={{ width: '100%', border: '1px solid #0A313F' }} />
              </button>
            )}
            <button type="button" style={{ background: 'transparent', borderStyle: 'none' }} onClick={onNext}>
              <p style={{ fontSize: 16, fontWeight: '600', color: '#0A313F', margin: 0, padding: 0 }}>NEXT</p>
              <div style={{ width: '100%', border: '1px solid #0A313F' }} />
            </button>
          </div>
        </UICard>
      </CardContainer>
      <UIModal
        actions={[
          {
            label: 'Cancel',
            onClick: closeExistingModal,
            buttonVariant: 'secondary',
          },
          {
            label: 'Proceed',
            onClick: addExistingContact,
            buttonVariant: 'primary',
          },
        ]}
        isOpen={showExistingModal}
        close={closeExistingModal}
      >
        <ModalContentContainer>
          <TitleText>The phone number</TitleText>
          <MainMessageText>{'+' + phoneToValidate}</MainMessageText>
          <InfoText>is already associated with an existing contact in our system.</InfoText>
          <MainMessageText>{`${updateContactData.firstName} ${updateContactData.lastName}.`}</MainMessageText>
          <InfoText>Do you want to add it as a contact for</InfoText>
          <MainMessageText>{`${allData?.deviceUser?.deviceUserFirstName} ${allData?.deviceUser?.deviceUserLastName}`}</MainMessageText>
          <InfoText>as well?</InfoText>
        </ModalContentContainer>
      </UIModal>
    </>
  );
};

export default DeviceContactsForm;
