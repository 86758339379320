import { httpApi as httpNodeApi } from '@nucleus-care/nucleuscare-backend-client';
import AutoCompleteAddress from 'components/AutoCompleteAddress/AutoCompleteAddress';
import { NucleusControlledWhiteBgInput } from 'components/NucleusControlledInput';
import OnBoardingGuide from 'components/OnboardingGuide/OnBoardingGuide';
import { UICard, UIModal } from 'components/UI';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import 'react-phone-input-2/lib/style.css';

const CardContainer = styled.div`
  display: flex;
  width: 45%;
  margin-left: 5%;
  margin-top: 3%;
  margin-bottom: 3%;
  @media (max-width: 1024px) {
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-left: 0;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-left: 0;
    margin-top: 0;
  }
`;

const InputsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const InputContainer = styled.div`
  width: calc(50% - 10px);
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 430px) {
    width: 140px;
  }
  @media (max-width: 320px) {
    width: 95px;
  }
`;

const PhoneInputDescriptionLabel = styled.p`
  color: #fe3824;
  font-size: 10px;
  margin-top: 5px;
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

const ModalContentContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TitleText = styled.p`
  text-align: center;
  color: #0a313f;
  font-size: 20px;
  font-weight: 400;
`;

const MainMessageText = styled.p`
  text-align: center;
  color: #0a313f;
  font-size: 24px;
  font-weight: 600;
`;

const InfoText = styled.p`
  text-align: center;
  color: #0a313f;
  font-size: 20px;
  font-weight: 400;
`;

const DeviceUserForm = ({
  allData,
  data,
  onChange,
  next,
  back,
  currentStep,
  handleIsUsingExistingPatient,
  deviceQuantity,
  shippedStatus,
  preferredShippingAddress,
  handlePatientHasExistingContacts,
}) => {
  const { consumerOrderId } = useParams();
  const [shouldValidatePhone, setShouldValidatePhone] = useState(false);
  const [phoneToValidate, setPhoneToValidate] = useState('');
  const [showExistingModal, setShowExistingModal] = useState(false);
  const [updateContactData, setUpdateContactData] = useState({ firstName: '', lastName: '', patientId: '', familyMemberCount: 0 });
  const [deviceNames, setDeviceNames] = useState(Array.from({ length: deviceQuantity }, (_, i) => data.deviceUserDeviceNames?.[i] || ''));

  const {
    register,
    formState: { errors },
    clearErrors,
    setValue,
    trigger,
    setError,
  } = useForm({
    defaultValues: data,
  });

  const handleInputChange = (name, value) => {
    const invalidChars = /[@$%^*[\]{}~<>|]/g;
    let sanitizedValue = value?.replace(invalidChars, '');
    if (sanitizedValue.length > 30 && name !== 'deviceUserAddress') {
      sanitizedValue = sanitizedValue.substring(0, 30);
    }
    onChange({ ...data, [name]: sanitizedValue });
  };

  const handleDeviceNameChange = (index, value) => {
    const updatedDeviceNames = [...deviceNames];
    updatedDeviceNames[index] = value;
    setDeviceNames(updatedDeviceNames);
    onChange({ ...data, deviceUserDeviceNames: updatedDeviceNames });
  };

  const isValidPhoneNumber = deviceUserPhoneNumber => {
    if (!deviceUserPhoneNumber) {
      return false;
    }

    const phoneRegex = /^(\+\d{1,3})?[\s.-]?\(?\d{1,4}\)?[\s.-]?\d{1,4}[\s.-]?\d{1,9}$/;
    return deviceUserPhoneNumber.length >= 10 && phoneRegex.test(deviceUserPhoneNumber);
  };

  const validatePhone = deviceUserPhoneNumber => {
    if (!isValidPhoneNumber(deviceUserPhoneNumber)) {
      setError('deviceUserPhoneNumber', {
        type: 'manual',
        message: 'Invalid phone number',
      });
      return false;
    } else {
      clearErrors('deviceUserPhoneNumber');
      return true;
    }
  };

  const onNext = async e => {
    e.preventDefault();
    const isValidForm = await trigger();
    const isPhoneValid = validatePhone(data.deviceUserPhoneNumber);

    if (isValidForm && isPhoneValid) {
      next();
    }
  };

  const closeExistingModal = () => {
    setValue(`deviceUserPhoneNumber`, '');
    onChange({
      ...data,
      deviceUserPhoneNumber: '',
    });
    setShowExistingModal(false);
    setPhoneToValidate('');
    setUpdateContactData({ firstName: '', lastName: '', patientId: '', familyMemberCount: 0 });
  };

  const addExistingContact = async () => {
    onChange({
      ...data,
      deviceUserFirstName: updateContactData.firstName,
      deviceUserLastName: updateContactData.lastName,
    });
    if (updateContactData.familyMemberCount > 0) {
      handlePatientHasExistingContacts(true);
    } else {
      handlePatientHasExistingContacts(false);
    }
    setPhoneToValidate('');
    setShowExistingModal(false);
    setUpdateContactData({ firstName: '', lastName: '', patientId: '', familyMemberCount: 0 });
    handleIsUsingExistingPatient(true, updateContactData.patientId);
  };

  useEffect(() => {
    if (shouldValidatePhone) {
      const phoneParam = encodeURIComponent('+' + phoneToValidate);
      httpNodeApi
        .get(`consumer-orders/${consumerOrderId}/check-patient-phone-registered?telephone=${phoneParam}`)
        .then(response => {
          if (response.data.telephoneRegistered) {
            setUpdateContactData({
              firstName: response.data.firstName,
              lastName: response.data.lastName,
              patientId: response.data.patientID,
              familyMemberCount: response.data.familyMemberCount,
            });
            setShowExistingModal(true);
          } else {
            handlePatientHasExistingContacts(false);
          }
        })
        .catch(error => {
          console.log('Error: ', error);
        });
    }
    setShouldValidatePhone(false);
  }, [shouldValidatePhone]);

  useEffect(() => {
    if (data) {
      Object.keys(data).forEach(key => {
        setValue(key, data[key]);
      });
    }
  }, [data, setValue]);

  const handleOnChangeShipTo = value => {
    onChange({ deviceOrderer: allData?.modifiedAddress?.deviceOrderer, deviceUser: value }, ['modifiedAddress']);
  };

  return (
    <>
      <OnBoardingGuide onNext={onNext} back={back} currentStep={currentStep} patientName={''} />
      <CardContainer>
        <UICard
          title="Device User"
          info="This is the person that will be using the product"
          style={{
            backgroundColor: '#FFFFFF',
            width: '80%',
            paddingBottom: 20,
          }}
        >
          <div style={{ width: '100%', marginTop: 20 }}>
            <form>
              <InputsContainer>
                <InputContainer>
                  <>
                    <p style={{ fontWeight: 600, fontSize: 16, color: 'rgb(0, 146, 255)', paddingBottom: '2%', margin: 0 }}>
                      Phone Number<span style={{ color: '#FE3824' }}>*</span>:
                    </p>
                    <PhoneInput
                      country={'us'}
                      value={data.deviceUserPhoneNumber || ''}
                      onChange={deviceUserPhoneNumber => {
                        handleInputChange('deviceUserPhoneNumber', deviceUserPhoneNumber);
                        validatePhone(deviceUserPhoneNumber);
                      }}
                      onBlur={() => {
                        setShouldValidatePhone(true);
                        setPhoneToValidate(data.deviceUserPhoneNumber);
                      }}
                      inputStyle={{
                        width: '100%',
                        minHeight: 50,
                        touchAction: 'manipulation',
                      }}
                      containerStyle={{
                        marginBottom: 20,
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        border: '1px solid #0092FF',
                        borderRadius: 5,
                      }}
                      specialLabel="Cellphone number"
                    />
                    {errors.deviceUserPhoneNumber?.message && <PhoneInputDescriptionLabel>{String(errors.deviceUserPhoneNumber.message)}</PhoneInputDescriptionLabel>}
                  </>
                </InputContainer>
                <InputContainer>
                  <NucleusControlledWhiteBgInput
                    required
                    disabled={false}
                    label={'First Name'}
                    name={'deviceUserFirstName'}
                    register={register('deviceUserFirstName', {
                      required: 'First name is required',
                    })}
                    error={errors.deviceUserFirstName}
                    clearErrors={clearErrors}
                    setValue={setValue}
                    value={data.deviceUserFirstName || ''}
                    onChange={e => handleInputChange('deviceUserFirstName', e.target.value)}
                    inputStyle={{
                      minHeight: 50,
                    }}
                    containerStyle={{
                      marginBottom: 20,
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                    labelStyle={{
                      color: '#0092FF',
                    }}
                  />
                </InputContainer>

                <InputContainer>
                  <NucleusControlledWhiteBgInput
                    required
                    disabled={false}
                    label={'Last Name'}
                    name={'deviceUserLastName'}
                    register={register('deviceUserLastName', {
                      required: 'Last name is required',
                    })}
                    error={errors.deviceUserLastName}
                    clearErrors={clearErrors}
                    setValue={setValue}
                    value={data.deviceUserLastName || ''}
                    onChange={e => handleInputChange('deviceUserLastName', e.target.value)}
                    inputStyle={{
                      minHeight: 50,
                    }}
                    containerStyle={{
                      marginBottom: 20,
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                    labelStyle={{
                      color: '#0092FF',
                    }}
                  />
                </InputContainer>
                <InputContainer>
                  <AutoCompleteAddress
                    onChangeShipTo={handleOnChangeShipTo}
                    required
                    disabled={shippedStatus}
                    label={'Address'}
                    name={'deviceUserAddress'}
                    register={register('deviceUserAddress', {
                      required: 'Address is required',
                    })}
                    error={errors.deviceUserAddress}
                    clearErrors={clearErrors}
                    setValue={setValue}
                    value={data.deviceUserAddress || ''}
                    onChange={e => handleInputChange('deviceUserAddress', e.target.value)}
                  />
                </InputContainer>

                {Array.from({ length: deviceQuantity }, (_, index) => (
                  <InputContainer key={index}>
                    <NucleusControlledWhiteBgInput
                      required
                      tooltip
                      tooltipContent={"You can customize the \ndevice's name here (e.g.\nKitchen, Office, Living\nRoom). The name will be \nvisible on the device's \nscreensaver."}
                      label={`Device Name ${index + 1}`}
                      name={`deviceUserDeviceName${index}`}
                      register={register(`deviceUserDeviceNames.${index}`, {
                        required: 'Device name is required',
                      })}
                      error={errors.deviceUserDeviceNames?.[index]}
                      clearErrors={clearErrors}
                      setValue={setValue}
                      value={deviceNames[index] || ''}
                      onChange={e => handleDeviceNameChange(index, e.target.value)}
                      inputStyle={{
                        minHeight: 50,
                      }}
                      containerStyle={{
                        marginBottom: 20,
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                      labelStyle={{
                        color: '#0092FF',
                      }}
                    />
                  </InputContainer>
                ))}
                {shippedStatus && (
                  <p style={{ fontSize: 16, fontWeight: '600', color: '#0A313F', marginTop: 0 }}>{`DEVICE SHIPPED TO ${preferredShippingAddress.toUpperCase()}`}</p>
                )}
                {!shippedStatus && (
                  <>
                    <p style={{ fontSize: 16, fontWeight: '600', color: '#0A313F', marginTop: 0 }}>BY DEFAULT, THE DEVICE SHIPS TO THE DEVICE USER. YOU CAN MODIFY THIS BELOW.</p>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ display: 'flex', marginBottom: 25 }}>
                        <div style={{ width: 160 }}>
                          <p style={{ fontSize: 16, color: '#0A313F', fontWeight: '300', margin: 0 }}>Ship to Device Orderer</p>
                          <p style={{ fontSize: 12, color: '#0A313F', fontWeight: '300', margin: 0, marginTop: 5 }}>{allData?.orderer?.deviceOrdererAddress}</p>
                        </div>
                        <CheckboxContainer>
                          <input
                            style={{ width: 20, height: 20, marginLeft: 30 }}
                            type="checkbox"
                            checked={data.shipTo === 'DEVICE_ORDERER'}
                            onChange={() => handleInputChange('shipTo', 'DEVICE_ORDERER')}
                          />
                        </CheckboxContainer>
                      </div>
                      <div style={{ display: 'flex', marginBottom: 25 }}>
                        <div style={{ width: 160 }}>
                          <p style={{ fontSize: 16, color: '#0A313F', fontWeight: '300', margin: 0 }}>Ship to Device User</p>
                          <p style={{ fontSize: 12, color: '#0A313F', fontWeight: '300', margin: 0, marginTop: 5 }}>{data?.deviceUserAddress}</p>
                        </div>
                        <CheckboxContainer>
                          <input
                            style={{ width: 20, height: 20, marginLeft: 30 }}
                            type="checkbox"
                            checked={data.shipTo === 'DEVICE_USER'}
                            onChange={() => handleInputChange('shipTo', 'DEVICE_USER')}
                          />
                        </CheckboxContainer>
                      </div>
                    </div>
                  </>
                )}
              </InputsContainer>
              <div style={{ width: '100%', display: 'flex', justifyContent: currentStep > 0 ? 'space-between' : 'flex-end', flexDirection: 'row' }}>
                {currentStep > 0 && (
                  <button style={{ background: 'transparent', borderStyle: 'none' }} onClick={back}>
                    <p style={{ fontSize: 16, fontWeight: '600', color: '#0A313F', margin: 0, padding: 0 }}>BACK</p>
                    <div style={{ width: '100%', border: '1px solid #0A313F', margin: 0, padding: 0 }} />
                  </button>
                )}
                <button type="submit" style={{ background: 'transparent', borderStyle: 'none' }} onClick={onNext}>
                  <p style={{ fontSize: 16, fontWeight: '600', color: '#0A313F', margin: 0, padding: 0 }}>NEXT</p>
                  <div style={{ width: '100%', border: '1px solid #0A313F' }} />
                </button>
              </div>
            </form>
          </div>
        </UICard>
      </CardContainer>
      <UIModal
        actions={[
          {
            label: 'Cancel',
            onClick: closeExistingModal,
            buttonVariant: 'secondary',
          },
          {
            label: 'Proceed',
            onClick: addExistingContact,
            buttonVariant: 'primary',
          },
        ]}
        isOpen={showExistingModal}
        close={closeExistingModal}
      >
        <ModalContentContainer>
          <TitleText>The phone number</TitleText>
          <MainMessageText>{'+' + phoneToValidate}</MainMessageText>
          <InfoText>is already associated with an existing user in our system.</InfoText>
          <MainMessageText>{`${updateContactData.firstName} ${updateContactData.lastName}.`}</MainMessageText>
          <InfoText>Do you want to assign it to this device as well?</InfoText>
        </ModalContentContainer>
      </UIModal>
    </>
  );
};

export default DeviceUserForm;
