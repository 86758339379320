import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { FieldError, FieldErrorsImpl, FieldValues, Merge, Path, PathValue, UseFormClearErrors, UseFormRegisterReturn, UseFormSetValue } from 'react-hook-form';
import { PlacesType, Tooltip as ReactTooltip } from 'react-tooltip';
import styled from 'styled-components';

const TooltipIcon = styled.span`
  display: inline-block;
  margin-left: 5px;
  color: #0092ff;
  cursor: pointer;
  font-size: 16px;
`;

const CustomIcon = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #0092ff;
  border-radius: 50%;
  text-align: center;
  line-height: 17px;
  color: #0092ff;
  font-weight: 800;
  cursor: pointer;
  font-size: 12px;
`;

interface NucleusWhiteBgInputProps<T extends FieldValues> {
  noLabel?: boolean;
  register?: UseFormRegisterReturn;
  name: keyof T | string;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<T>>;
  id?: string;
  label?: string;
  clearErrors?: UseFormClearErrors<T>;
  setValue?: UseFormSetValue<T>;
  containerStyle?: React.CSSProperties;
  inputContainerStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  mainContainerStyle?: React.CSSProperties;
  showPasswordAction?: () => void;
  saveHandler?: () => void;
  saveText?: string;
  saveStyle?: React.CSSProperties;
  goToAction?: () => void;
  disabled?: boolean;
  disabledSave?: boolean;
  required?: boolean;
  tooltip?: boolean;
  tooltipContent?: React.ReactNode;
  [key: string]: unknown; // Para cualquier prop adicional
}

const NucleusWhiteBgInput = <T extends FieldValues>({
  noLabel = false,
  register,
  name,
  error,
  id,
  label,
  clearErrors,
  setValue,
  containerStyle,
  inputContainerStyle,
  inputStyle,
  labelStyle,
  mainContainerStyle,
  showPasswordAction,
  saveHandler,
  saveText,
  saveStyle,
  goToAction,
  disabled = false,
  disabledSave = false,
  required = false,
  tooltip = false,
  tooltipContent,
  ...props
}: NucleusWhiteBgInputProps<T>) => {
  const GoToAction = styled.button<{ saveStyle?: React.CSSProperties }>`
    align-self: center;
    cursor: pointer;
    color: #0092ff;
    text-align: center;
    font-weight: bold;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    ${({ saveStyle }) => saveStyle && { ...saveStyle }};
  `;

  const SaveAction = styled.button<{ disabledSave?: boolean; saveStyle?: React.CSSProperties }>`
    align-self: center;
    cursor: pointer;
    color: ${({ disabledSave }) => (disabledSave ? '#D8F0FE' : '#0092FF')};
    text-align: center;
    font-weight: bold;
    margin-left: 10px;
    ${({ saveStyle }) => saveStyle && { ...saveStyle }};
  `;

  const [tooltipPlace, setTooltipPlace] = useState<PlacesType | undefined>('bottom');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768 && window.innerWidth < 1024) {
        setTooltipPlace('right');
      } else {
        setTooltipPlace('bottom');
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const disabledHandler = () => {};

  return (
    <div style={{ minHeight: 70, ...mainContainerStyle }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          ...containerStyle,
        }}
      >
        {!noLabel && (
          <label
            style={{
              minWidth: 130,
              textAlign: 'start',
              fontWeight: '600',
              fontSize: 16,
              color: '#0A313F',
              marginBottom: '2%',
              ...labelStyle,
            }}
            htmlFor={name.toString()}
            id={id}
          >
            {label}
            {required && <span style={{ color: '#FE3824' }}>*</span>}
            {':'}
            {tooltip && (
              <>
                <TooltipIcon data-tooltip-id={`tooltip-${String(name)}`}>
                  <CustomIcon>i</CustomIcon>
                </TooltipIcon>
                <ReactTooltip
                  id={`tooltip-${String(name)}`}
                  place={tooltipPlace}
                  style={{ backgroundColor: '#0A313F', whiteSpace: 'pre-wrap', textAlign: 'left', zIndex: 100 }}
                  clickable={true}
                >
                  {tooltipContent}
                </ReactTooltip>
              </>
            )}
          </label>
        )}
        <div
          style={{
            backgroundColor: '#fff',
            width: '100%',
            borderRadius: 5,
            border: error ? '1px solid #FE3824' : '1px solid #0092FF',
            display: 'flex',
            flexDirection: 'row',
            ...inputContainerStyle,
          }}
        >
          <style>
            {`
            .white-bg-input:focus {
              border-bottom: 0 !important;
              box-shadow: none !important;
              padding-bottom: 0px !important;
              height: 2rem !important;
              margin: 0 !important;
              outline: none;
              width: 105%;
            }
            .white-bg-input {
              border-bottom: 0 !important;
              border-radius: 5px;
              box-shadow: none !important;
              padding-bottom: 0px !important;
              padding: 0px 10px;
              height: 2rem !important;
              margin: 0 !important;
              border-style: none;
              width: 100%;
              touch-action: manipulation;
            }
            `}
          </style>
          <input
            disabled={disabled}
            required={required}
            onChange={e => {
              if (error && clearErrors) {
                clearErrors(name as Path<T>);
              }
              if (setValue) {
                setValue(name as Path<T>, e.target.value as PathValue<T, Path<T>>);
              }
            }}
            className="white-bg-input"
            name={name.toString()}
            id={id}
            style={{
              opacity: disabled ? 0.5 : 1,
              ...inputStyle,
            }}
            {...register}
            {...props}
          />
          {showPasswordAction && (
            <img src="img/view_password.png" className="nucleus-link" style={{ width: 24, height: 16, marginLeft: -24, marginTop: 8 }} onClick={showPasswordAction} />
          )}
          {!!goToAction && (
            <GoToAction saveStyle={saveStyle} onClick={goToAction}>
              Go
            </GoToAction>
          )}
          {!!saveHandler && (
            <SaveAction onClick={disabledSave ? disabledHandler : saveHandler} disabledSave={disabledSave} saveStyle={saveStyle}>
              {saveText || 'Save'}
            </SaveAction>
          )}
          {error && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#FE3824',
                borderRadius: '50%',
                width: '12px',
                height: '12px',
                marginTop: 20,
                marginRight: 10,
              }}
            >
              <FontAwesomeIcon icon={faCircle} style={{ color: '#FE3824', fontSize: '12px', position: 'absolute' }} />
              <p style={{ color: 'white', fontSize: '10px', zIndex: 100, fontWeight: '800' }}>!</p>
            </div>
          )}
        </div>
        {error && (
          <p
            style={{
              color: '#FE3824',
              fontWeight: '700',
              fontSize: 10,
              margin: 5,
            }}
          >
            {typeof error?.message === 'string' && error.message}
          </p>
        )}
      </div>
    </div>
  );
};

export default NucleusWhiteBgInput;
