import { NucleusControlledWhiteBgInput } from 'components/NucleusControlledInput';
import OnBoardingGuide from 'components/OnboardingGuide/OnBoardingGuide';
import { UICard } from 'components/UI';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
const CardContainer = styled.div`
  display: flex;
  width: 45%;
  margin-left: 5%;
  margin-top: 3%;
  margin-bottom: 3%;
  @media (max-width: 1024px) {
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-left: 0;
  }

  @media (max-width: 767px) {
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-left: 0;
    margin-top: 0;
  }
`;

const InputsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const InputContainer = styled.div`
  width: 45%;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const ButtonsContainer = styled.div<{ currentStep: number }>`
  width: 100%;
  display: flex;
  justify-content: ${({ currentStep }) => (currentStep > 0 ? 'space-between' : 'flex-end')};
  flex-direction: row;
  @media (min-width: 1441px) {
    max-width: 1440px;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (min-width: 1441px) {
    min-width: 1440px;
  }
`;
const WifiForm = ({ data, onChange, next, back, currentStep }) => {
  const {
    register,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm();

  const handleInputChange = (name, value) => {
    onChange({ ...data, [name]: value });
  };

  return (
    <Container>
      <OnBoardingGuide onNext={next} back={back} currentStep={currentStep} patientName={''} />
      <CardContainer>
        <UICard
          title="Wifi"
          info="If you have the Wifi details for where this device will be used, you can enter them here."
          style={{
            width: '80%',
            paddingBottom: 20,
          }}
        >
          <form>
            <InputsContainer>
              <InputContainer>
                <NucleusControlledWhiteBgInput
                  disabled={false}
                  tooltip
                  tooltipContent="This is the name of the Wifi network"
                  label={'SSID'}
                  name={'wifiSsid'}
                  register={register('wifiSsid')}
                  error={errors.wifiSsid}
                  clearErrors={clearErrors}
                  setValue={setValue}
                  value={data.wifiSsid || ''}
                  onChange={e => handleInputChange('wifiSsid', e.target.value)}
                  inputStyle={{
                    minHeight: 50,
                  }}
                  //alternateBorder
                  containerStyle={{
                    marginBottom: 20,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                  labelStyle={{
                    color: '#0092FF',
                  }}
                />
              </InputContainer>

              <InputContainer>
                <NucleusControlledWhiteBgInput
                  disabled={false}
                  label={'Password'}
                  name={'wifiPassword'}
                  register={register('wifiPassword')}
                  error={errors.wifiPassword}
                  clearErrors={clearErrors}
                  setValue={setValue}
                  value={data.wifiPassword || ''}
                  onChange={e => handleInputChange('wifiPassword', e.target.value)}
                  inputStyle={{
                    minHeight: 50,
                  }}
                  //alternateBorder
                  containerStyle={{
                    marginBottom: 20,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                  labelStyle={{
                    color: '#0092FF',
                  }}
                />
              </InputContainer>
            </InputsContainer>
          </form>
          <ButtonsContainer currentStep={currentStep}>
            {currentStep > 0 && (
              <button style={{ background: 'transparent', borderStyle: 'none' }} onClick={back}>
                <p style={{ fontSize: 16, fontWeight: '600', color: '#0A313F', margin: 0, padding: 0 }}>BACK</p>
                <div style={{ width: '100%', border: '1px solid #0A313F', margin: 0, padding: 0 }} />
              </button>
            )}
            <button style={{ background: 'transparent', borderStyle: 'none' }} onClick={next}>
              <p style={{ fontSize: 16, fontWeight: '600', color: '#0A313F', margin: 0, padding: 0 }}>NEXT</p>
              <div style={{ width: '100%', border: '1px solid #0A313F' }} />
            </button>
          </ButtonsContainer>
        </UICard>
      </CardContainer>
    </Container>
  );
};

export default WifiForm;
