import { Autocomplete } from '@react-google-maps/api';
import { NucleusControlledWhiteBgInput } from 'components/NucleusControlledInput';
import { IShipTo } from 'pages/Purchase/Purchase';
import React, { useState } from 'react';

const AutoCompleteAddress = ({ name, label, required, disabled, register, error, clearErrors, setValue, value, onChange, onChangeShipTo }) => {
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);

  const handleLoad = autocompleteInstance => {
    setAutocomplete(autocompleteInstance);
  };

  const handlePlaceChanged = () => {
    if (autocomplete) {
      const place: google.maps.places.PlaceResult = autocomplete.getPlace();
      const address = place.formatted_address || '';
      setValue('deviceUserAddress', address);
      const shipTo: IShipTo = {
        city: place.address_components?.find(component => component.types.includes('locality'))?.long_name || '',
        state: place.address_components?.find(component => component.types.includes('administrative_area_level_1'))?.short_name || '',
        postalCode: place.address_components?.find(component => component.types.includes('postal_code'))?.long_name || '',
        country: place.address_components?.find(component => component.types.includes('country'))?.short_name || '',
        company: place.address_components?.find(component => component.types.includes('establishment'))?.long_name || '',
        name: place.address_components?.find(component => component.types.includes('establishment'))?.long_name || '',
        phone: place.formatted_phone_number || '',
        street1:
          place.address_components?.find(component => component.types.includes('street_number'))?.long_name +
            ' ' +
            place.address_components?.find(component => component.types.includes('route'))?.long_name || '',
        residential: false,
        addressVerified: '',
        street2: '',
        street3: '',
      };
      onChangeShipTo(shipTo);
      onChange({ target: { value: address } });
    }
  };

  return (
    <Autocomplete onLoad={handleLoad} onPlaceChanged={handlePlaceChanged}>
      <NucleusControlledWhiteBgInput
        required={required}
        disabled={disabled}
        label={label}
        name={name}
        register={register}
        error={error}
        clearErrors={clearErrors}
        setValue={setValue}
        value={value}
        onChange={onChange}
        inputStyle={{
          minHeight: 50,
        }}
        containerStyle={{
          marginBottom: 20,
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
        labelStyle={{
          color: '#0092FF',
        }}
      />
    </Autocomplete>
  );
};

export default AutoCompleteAddress;
