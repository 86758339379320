import React from 'react';
import styled from 'styled-components';
import logoDark from './assets/dark-nucleus-by-maxsip.svg';
import logo from './assets/nucleus-by-maxsip.svg';

type DarkModeProp = {
  dark?: boolean;
};

const HeaderWrapper = styled.div<DarkModeProp>`
  width: 100vw;
  justifycontent: 'center';
  alignitems: 'center';
  background-color: ${({ dark }) => (dark ? '#0A313F' : '#EBF2FE')};
  @media (min-width: 1441px) {
    display: flex;
    justify-content: center;
    alignitems: 'center';
  }
`;
const HeaderContainer = styled.div<DarkModeProp>`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 767px) {
    flex-direction: row;
    justify-content: space-between;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  @media (min-width: 1441px) {
    justify-content: space-between;
    width: 1440px;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  @media (min-width: 1024px) {
    flex: 0;
    min-width: 300px;
  }
  @media (min-width: 1441px) {
    flex: 1;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  object-fit: contain;
  @media (min-width: 1024px) {
    flex: 0;
    white-space: nowrap;
    margin-left: 5%;
  }
  @media (min-width: 1441px) {
    margin-left: 0px;
    flex: 1;
  }
`;

const Logo = styled.img`
  width: 100%;
  margin-right: 10px;
  @media (min-width: 1441px) {
    margin-right: 0px;
    max-width: 300px;
  }
`;

const Email = styled.a<DarkModeProp>`
  color: ${({ dark }) => (dark ? '#60BDFD' : '#0A313F')};
  text-decoration: underline;
  font-size: 16px;
  text-align: center;
  @media (max-width: 766px) {
    margin-top: 20px;
  }
  transition: font-weight 0.3s ease;
  &:hover {
    font-weight: bold;
  }
`;

const Phone = styled.a<DarkModeProp>`
  color: ${({ dark }) => (dark ? '#60BDFD' : '#0A313F')};
  text-decoration: underline;
  font-size: 16px;
  @media (max-width: 766px) {
    margin-top: 20px;
  }
  transition: font-weight 0.3s ease;
  &:hover {
    font-weight: bold;
  }
`;

const Header = ({ dark }: { dark?: boolean }) => {
  return (
    <HeaderWrapper dark={dark}>
      <HeaderContainer dark={dark}>
        <LogoWrapper>
          <Logo src={dark ? logoDark : logo} alt="Nucleus Logo" />
        </LogoWrapper>
        <ItemWrapper>
          <Email dark={dark} href="mailto:support@nucleuscare.com">
            support@nucleuscare.com
          </Email>
        </ItemWrapper>
        <ItemWrapper>
          <Phone dark={dark} href="tel:+15169511340">
            (516) 951-1340
          </Phone>
        </ItemWrapper>
      </HeaderContainer>
    </HeaderWrapper>
  );
};

export default Header;
