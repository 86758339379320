import styled from 'styled-components';
import BG1440 from '../../assets/bg-1440.png';
import MaxsipLogo from '../../components/Header/assets/dark-nucleus-by-maxsip.svg';
import DesktopXL from './assets/desktop-xl-maxsip.png';
import Logo from './assets/logo.png';
import Desktop from './assets/medium-bg-maxsip.png';
import Mobile from './assets/mobile-bg-maxsip.png';
import Tablet from './assets/tablet-bg-maxsip.png';

const ThankYouBackGround = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  width: 100%;
  height: 100vh;
  background-size: auto 100%;
  background-resize: center;
  background-repeat: no-repeat;
  background-position: top right;
  background-color: #0092ff;
  /* Default background image (Desktop) */
  background-image: url(${Desktop});

  /* Tablet background image */
  @media (max-width: 1024px) {
    background-image: url(${Tablet});
  }

  /* Mobile background image */
  @media (max-width: 768px) {
    background-position: top right;
    background-size: auto 75%;
    background-image: url(${Mobile});
  }
  @media (min-width: 1025px) {
    background-image: url(${DesktopXL});
  }
  @media (min-width: 1440px) {
    background-image: url(${BG1440});
  }
`;
const LogoImage = styled.img`
  width: 300px;
  height: auto;
  object-fit: contain;
  @media (max-width: 1024px) {
    width: 200px; /* Width for tablet */
  }

  @media (max-width: 768px) {
    width: 100px; /* Width for mobile */
  }
`;

const MainText = styled.p`
  font-size: 48px;
  color: #ffffff;
  font-weight: 700;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 32px; /* Font size for tablet */
  }

  @media (max-width: 768px) {
    font-size: 20px; /* Font size for mobile */
  }
`;

const SubText = styled.p`
  font-size: 24px;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
  margin: 5px 0;

  @media (max-width: 1024px) {
    font-size: 24px; /* Font size for tablet */
  }

  @media (max-width: 768px) {
    font-size: 16px; /* Font size for mobile */
    width: 79%;
  }
`;

const ThankYouContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
`;

const ContentInfo = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const BrandingFooter = styled.div`
  width: 208px;
  padding: 30px;
`;
const BrandingImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;
const Email = styled.a`
  color: #000000;
  text-decoration: underline;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  @media (max-width: 768px) {
    margin-top: 20px;
    font-size: 14px;
  }
  transition: font-weight 0.3s ease;
  &:hover {
    font-weight: bold;
  }
`;

const Phone = styled.a`
  color: #000000;
  text-decoration: underline;
  font-size: 24px;
  font-weight: 500;
  @media (max-width: 768px) {
    margin-top: 20px;
    font-size: 14px;
  }
  transition: font-weight 0.3s ease;
  &:hover {
    font-weight: bold;
  }
`;
const ThankYou = () => {
  return (
    <ThankYouBackGround>
      <ThankYouContainer>
        <ContentInfo>
          <LogoImage src={Logo} alt="Logo" />
          <MainText>Thank you for your form submission.</MainText>
          <SubText>If you'd like to make any additional changes, please contact customer support at</SubText>
          <SubText>
            <Email href="mailto:support@nucleuscare.com">support@nucleuscare.com </Email>
            or
            <Phone href="tel:+15169511340"> (516) 951-1340</Phone>.
          </SubText>
          <SubText>Our team will be happy to help you!</SubText>
        </ContentInfo>
        <Footer>
          <BrandingFooter>
            <BrandingImage src={MaxsipLogo} />
          </BrandingFooter>
        </Footer>
      </ThankYouContainer>
    </ThankYouBackGround>
  );
};

export default ThankYou;
