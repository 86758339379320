import { ReactNode, FC } from 'react';
import styled from 'styled-components';

export type CardProps = {
  children?: ReactNode;
  title?: string;
  info?: string;
  style?: React.CSSProperties;
  cardHeaderStyle?: React.CSSProperties;
  infoStyle?: React.CSSProperties;
};

export const UICard: FC<CardProps> = ({ children, title, info, style, cardHeaderStyle, infoStyle }) => {
  return (
    <CardContainer onClick={e => e.stopPropagation()} style={style}>
      {title && (
        <>
          <br />
          <CardHeader style={cardHeaderStyle}>{title}</CardHeader>
        </>
      )}
      {info && <CardHeaderInfo style={infoStyle}>{info}</CardHeaderInfo>}
      {children}
    </CardContainer>
  );
};

const CardContainer = styled.div<{ style?: React.CSSProperties }>`
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #ebf2fe;
  position: relative;
  width: ${props => props.style?.width};
  height: ${props => props.style?.height};
  background-color: ${props => props.style?.backgroundColor};
  background: ${props => props.style?.background};
`;

const CardHeader = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  text-align: left;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 18px;
    font-weight: 600px;
    letter-spacing: 0px;
  }
  @media (min-width: 1024px) {
    font-size: 24px;
  }
`;

const CardHeaderInfo = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  text-align: left;
  font-weight: 300;
  color: black;
  font-style: italic;
  @media (max-width: 768px) {
    font-size: 16px;
    font-weight: 300px;
    letter-spacing: 0px;
  }
`;
