export const ageOfPurchaserOptions = [
  { value: 'under 18', label: 'Under 18' },
  { value: '18 - 24', label: '18 - 24' },
  { value: '25 - 34', label: '25 - 34' },
  { value: '35 - 44', label: '35 - 44' },
  { value: '45 - 54', label: '45 - 54' },
  { value: '55 - 64', label: '55 - 64' },
  { value: '65 or older', label: '65 or older' },
];

export const ageOfUserOptions = [
  { value: '18 - 24', label: '18 - 24' },
  { value: '25 - 34', label: '25 - 34' },
  { value: '35 - 44', label: '35 - 44' },
  { value: '45 - 54', label: '45 - 54' },
  { value: '55 - 64', label: '55 - 64' },
  { value: '65 or older', label: '65 or older' },
];

export const householdSizeOptions = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5 or more', label: '5 or more' },
];

export const maritalStatusOptions = [
  { value: 'Single', label: 'Single' },
  { value: 'Married', label: 'Married' },
  { value: 'Divorced', label: 'Divorced' },
  { value: 'Widowed', label: 'Widowed' },
  { value: 'Other', label: 'Other' },
];

export const genderOptions = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
  { value: 'Non-binary', label: 'Non-binary' },
  { value: 'Prefer not to say', label: 'Prefer not to say' },
];
